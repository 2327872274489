.container {
  @apply relative right-2;
}

.logosContainer {
  @apply flex;
}

.paymentsLogo {
  @apply m-2;
}
